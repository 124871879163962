export enum routes {
    INFO_PAGE = 'InfoPage',
    THANK_YOU_PAGE = 'ThankYouPage',
    CONTACT_PAGE = 'FormPage',
    USER_INFO_PAGE = 'UserInfoPage',
    PREPARATION_PAGE = 'PreparationPage',
    SOUNDOUTPUT_PAGE = 'SoundOutputPage',
    TONAL_TEST_PAGE = 'TonalTestPage',
    WORDS_TEST_PAGE = 'WordsTestPage',
    NUMBERS_TEST_PAGE = "NumbersTestPage",
    NOISE_TEST_PAGE = "NoiseTestPage",
    QUESTIONNAIRE_PAGE = "QuestionnairePage",
    ERROR_NOTIFICATION_PAGE = 'ErrorNotificationPage',
    SOLSTICIO_PAGE = 'SolsticioPage',
    TONAL_TEST_RESULT_PAGE = 'TonalTestResultPage',
    NOISE_TEST_RESULT_PAGE = 'NoiseTestResultPage',
    BIOSIGNALS_SCAN_TEST_PAGE = 'BiosignalsScanTestPage',
}
