
export interface ConsentDataOption {
    id: number,
    text: string,
    order: number,
    type: ConsentDataOptionType,
    mandatory: boolean
}

export enum ConsentDataOptionType {
    ACCEPTANCE = "acceptance",
    MARKETING = "marketing",
    TOPARTNER = "toPartner",
}
