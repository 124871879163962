import Vue from 'vue'
import axios, {AxiosStatic} from "axios";
import { store } from '../vuex/store';
//axios.defaults.baseURL = process.env.VUE_APP_IFRAME_CLIENT_BASE_URL
axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_IFRAME_CLIENT_INTERNAL_TOKEN
axios.defaults.timeout = 7000
axios.interceptors.response.use(function (response) {
    store.state.showErrorNotification = false
    return response;
}, function (error) {
    const err = error.toString()
    console.log(err)

    store.state.showErrorNotification = true
    store.state.axiosRequestCase = (err.includes("timeout") || error.response.status >= 500)
        ? 'error' : 'info'

    store.state.finishLoading = true
    return Promise.reject(error);
});

export const ApiService = {
    query (resource: any, params: any) {
        return axios.get(resource, {params})
    },

    get (resource: any, slug = '', config = {}) {
        return axios.get(slug !== '' ? `${resource}/${slug}` : resource, config )
    },

    post (resource: string, params = {}, options = {}) {
        return axios.post(`${resource}`, params, options)
    },

    patch (resource: string, params = {}, options = {}) {
        return axios.patch(`${resource}`, params, options)
    },
}
