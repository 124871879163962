<template>
  <div v-show="isLoading" class="loading-placeholder loading-wrapper">
    <div class="text-center">
      <!--<span class="is-bold"> {{ $t('loading') }} </span>-->
    </div>
    <LoadingAnimation :size="'5px'" class="d-flex justify-content-center pt-4 pb-4" />
  </div>
</template>

<script>
import LoadingAnimation from '@/components/Common/LoadingAnimation'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    LoadingAnimation
  }
}
</script>

<style lang="scss" scoped>
.loading-placeholder.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
}
</style>
