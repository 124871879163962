import {createWebHistory, createRouter, createWebHashHistory} from "vue-router";
import IframeIndex from "@/components/IframeIndex.vue";
import lazyLoading from './lazyLoading'
import {routes as routesEnum} from '@/Enums/Routes'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: IframeIndex,
        children: [
            {
                name: routesEnum.INFO_PAGE,
                path: "*",
                component: lazyLoading('InfoPage/InfoPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.THANK_YOU_PAGE,
                path: "*",
                component: lazyLoading('ThankYou/ThankYouPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.CONTACT_PAGE,
                path: "*",
                component: lazyLoading('Form/Form'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.USER_INFO_PAGE,
                path: "*",
                component: lazyLoading('UserInfo/UserInfoPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.PREPARATION_PAGE,
                path: "*",
                component: lazyLoading('Preparation/PreparationPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.SOUNDOUTPUT_PAGE,
                path: "*",
                component: lazyLoading('SoundOutput/SoundOutputPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.TONAL_TEST_PAGE,
                path: "*",
                component: lazyLoading('HearingTest/TonalTestPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.QUESTIONNAIRE_PAGE,
                path: "*",
                component: lazyLoading('Questionnaire/QuestionnairePage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.NOISE_TEST_PAGE,
                path: "*",
                component: lazyLoading('NoiseTest/NoiseTestPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.ERROR_NOTIFICATION_PAGE,
                path: "*",
                component: lazyLoading('ErrorNotification/ErrorNotificationPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.SOLSTICIO_PAGE,
                path: "*",
                component: lazyLoading('Solsticio/SolsticioPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.TONAL_TEST_RESULT_PAGE,
                path: "*",
                component: lazyLoading('HearingTest/TonalTestResultsPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.NOISE_TEST_RESULT_PAGE,
                path: "*",
                component: lazyLoading('NoiseTest/NoiseTestResultPage'),
                props: (route: any) => ({
                    ...route.params
                })
            },
            {
                name: routesEnum.BIOSIGNALS_SCAN_TEST_PAGE,
                path: "*",
                component: lazyLoading('Biosignals/BiosignalsScanTestPage'),
                props: (route: any) => ({
                    ...route.params
                })
            }
        ]
    }
    ];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router
