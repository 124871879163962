import Vue, { createApp } from 'vue'
import App from './App.vue'
import { createI18n} from "vue-i18n";
import en from './i18n/en.json'
import pt from './i18n/pt.json'
import es from './i18n/es.json'
import router from './Router'
import mitt from 'mitt'


const emitter = mitt()

import {store, key} from './vuex/store'

import './sass/main.scss'

const app = createApp(App)

export const languages = {
    pt: pt,
    en: en,
    es: es
}

const i18n = createI18n({
    locale:'pt',
    fallbackLocale: 'en',
    messages: Object.assign(languages)
})

app.use(store, key)
app.use(i18n)
app.use(router)
app.provide('emitter', emitter)
app.mount('#app')

