export enum PageTypes {
    INFO_PAGE = 'info_page',
    HEARING_TEST_PAGE = 'hearing_test_page',
    CONTACT_PAGE = 'contact_page',
    QUESTIONNAIRE_PAGE = 'questionnaire_page',
    USER_INFO_PAGE = 'user_info_page',
    THANK_YOU_PAGE = 'thanks_page',
    OUTPUT_PAGE = 'output_page',
    PREPARATION_PAGE = 'preparation_page',
    TONAL_TEST_PAGE = 'tonal_test_page',
    ERROR_NOTIFICATION_PAGE = 'error_notification_page',
    SOLSTICIO = 'solsticio_page',
    TONAL_TEST_RESULT_PAGE = 'tonal_test_result_page',
    NOISE_TEST_RESULT_PAGE = 'noise_test_result_page',
    NOISE_TEST_PAGE = 'noise_test_page',
    BIOSIGNALS_SCAN_TEST_PAGE = 'biosignals_scan_test_page',
}
